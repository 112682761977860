<template>
  <div class="card task-board-left">
    <div class="card-header text-capitalize">
      <h5>Recherche avancée</h5>
    </div>
    <div class="card-block pt-1 bg-light">
      <form @submit.prevent="handleSearchTicketFilter" class="form">
        <!--        <div class="form-group">-->
        <!--          <label class="f-w-500" for="filter-ticketType">Type de ticket</label>-->
        <!--          <select-->
        <!--            class="form-control"-->
        <!--            id="filter-ticketType"-->
        <!--            v-model="type_tickets_id"-->
        <!--          >-->
        <!--            <option :value="null">Tout</option>-->
        <!--            <option-->
        <!--              v-for="ticketType in ticket_types"-->
        <!--              class="text-capitalize"-->
        <!--              :key="ticketType.id"-->
        <!--              :value="ticketType.id"-->
        <!--            >-->
        <!--              {{ ticketType.label }}-->
        <!--            </option>-->
        <!--          </select>-->
        <!--        </div>-->
        <div class="form-group">
          <label class="f-w-500" for="filter-country">Pays</label>
          <select class="form-control" id="filter-country" v-model="country_id">
            <option :value="null">Tout</option>
            <option
              v-for="country in countries"
              class="text-capitalize"
              :key="country.id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="f-w-500" for="filter-operator">Opérateur</label>
          <select
            class="form-control"
            id="filter-operator"
            :disabled="disableOperatorFilter"
            v-model="operators_id"
          >
            <option :value="null">Tout</option>
            <option
              v-for="operator in operatorList"
              class="text-capitalize"
              :key="operator.id"
              :value="operator.id"
            >
              {{ operator.label }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="f-w-500" for="filter-status"
            >Statut de l'opération</label
          >
          <select
            class="form-control"
            id="filter-status"
            v-model="status"
            :disabled="isOtherTypeSelected"
          >
            <option :value="null">Tout</option>
            <option
              v-for="state in statusList"
              :key="state.id"
              :value="state.id"
            >
              {{ state.label }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="f-w-500" for="filter-api">API de traitement</label>
          <select
            class="form-control text-capitalize"
            id="filter-api"
            v-model="api_code"
            :disabled="isOtherTypeSelected"
          >
            <option :value="null">Tout</option>
            <option v-for="api in apiList" :key="api.code" :value="api.code">
              {{ api.label }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="f-w-500" for="filter-state">Statut du ticket</label>
          <select class="form-control" id="filter-state" v-model="state">
            <option :value="null">Tout</option>
            <option
              v-for="state in stateList"
              :key="state.id"
              :value="state.id"
            >
              {{ state.label }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="f-w-500" for="filter-status">Date de création</label>
          <BaseDatepicker
            :range="true"
            :value="dateInterval"
            @input="chooseDateInterval"
            :not-after="new Date()"
            placeholder="Intervale de date"
          />
        </div>
        <div class="form-group">
          <label class="mb-1 f-w-500" for="switch-ticket-read"
            >Date de l'opération</label
          >
          <div
            class="switch switch-primary d-inline m-l-10 d-block text-center"
          >
            <input
              type="checkbox"
              id="switch-ticket-read"
              v-model="is_operation_date"
              :disabled="isOtherTypeSelected || !dateInterval"
            />
            <label for="switch-ticket-read" class="cr"></label>
          </div>
        </div>
        <hr />
        <BaseButton
          buttonClass="btn-secondary btn-block"
          :loading="loading"
          :show-loading="true"
          :disabled="!is_key_change"
        >
          <i class="fas fa-search" v-if="!loading"></i>
          Rechercher
        </BaseButton>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ticketFieldMixin } from "../../mixins/ticketFieldMixin";
import {
  operationStatus,
  ticketStates,
  typeTicketCode
} from "../../helpers/constants";

export default {
  name: "TicketListFilter",
  mixins: [ticketFieldMixin],
  data() {
    return {
      loading: false,
      dateInterval: null
    };
  },

  created() {
    if (this.$route.params.type_tickets_id)
      this.$store.commit(
        "assistance/UPDATE_FIELD_TYPE_TICKETS_ID",
        parseFloat(this.$route.params.type_tickets_id)
      );
  },

  computed: {
    ...mapState(["countries"]),
    ...mapState("assistance", ["ticket_types", "operators", "api_list"]),

    disableOperatorFilter() {
      if (this.country_id) {
        const type = this.ticket_types.find(t => t.id === this.type_tickets_id);
        if (type !== undefined)
          return !(
            type.code === typeTicketCode.withdraw ||
            type.code === typeTicketCode.deposit ||
            type.code === typeTicketCode.withdraw
          );
        else return true;
      } else return true;
    },
    isOtherTypeSelected() {
      const type = this.ticket_types.find(t => t.id === this.type_tickets_id);
      if (type !== undefined) return type.code === typeTicketCode.other;
      else return true;
    },
    operatorList() {
      return this.country_id
        ? this.operators.filter(op => op.countries_id === this.country_id)
        : this.operators;
    },
    statusList() {
      return operationStatus;
    },
    stateList() {
      return ticketStates;
    },
    apiList() {
      if (this.isOtherTypeSelected) return [];
      else {
        const type = this.ticket_types.find(t => t.id === this.type_tickets_id);
        if (type !== undefined) {
          switch (type.code) {
            case typeTicketCode.deposit:
              return this.api_list.filter(a => a.is_deposit);
            case typeTicketCode.withdraw:
              return this.api_list.filter(a => a.is_withdraw);
            case typeTicketCode.payment:
              return this.api_list.filter(a => a.is_payment);
            default:
              return this.api_list.filter(a => a.is_shopping);
          }
        }
        return this.api_list;
      }
    }
  },

  methods: {
    handleSearchTicketFilter() {
      this.$emit("search");
      this.is_key_change = false;
    },

    chooseDateInterval(interval) {
      if (interval[0] !== null && interval[1] !== null) {
        const data = {
          start:
            interval[0].getFullYear() +
            "-" +
            (interval[0].getMonth() + 1) +
            "-" +
            interval[0].getDate(),
          end:
            interval[1].getFullYear() +
            "-" +
            (interval[1].getMonth() + 1) +
            "-" +
            interval[1].getDate()
        };
        if (data.start === data.end)
          this.$store.commit("assistance/UPDATE_FIELD_DATE", data.start);
        else
          this.$store.commit(
            "assistance/UPDATE_FIELD_DATE_INTERVAL",
            JSON.stringify(data)
          );
      } else {
        this.$store.commit("assistance/UPDATE_FIELD_DATE", null);
        this.$store.commit("assistance/UPDATE_FIELD_DATE_INTERVAL", null);
      }
      this.is_key_change = true;
      this.dateInterval = interval;
    }
  }
};
</script>

<style scoped></style>
