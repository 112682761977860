<template>
  <nav class="navbar m-b-5">
    <ul class="nav pl-0 align-items-center">
      <li class="nav-item f-text border-right p-r-10">
        <div class="form-group">
          <label for="listLenght" class="mb-1 f-w-500">Taille</label>
          <select class="form-control" id="listLenght" v-model="paginate">
            <option
              v-for="(paginate, index) in paginates"
              :key="`length-${index}`"
              :value="paginate"
            >
              {{ paginate }}
            </option>
          </select>
        </div>
      </li>
      <li class="nav-item f-text m-l-10 border-right p-r-10">
        <div class="form-group">
          <label for="orderByList" class="mb-1 f-w-500">Ordoner par</label>
          <select class="form-control" id="orderByList" v-model="order_by">
            <option
              v-for="(order, index) in orderByList"
              :key="`order-${index}`"
              :value="order.code"
            >
              {{ order.label }}
            </option>
          </select>
        </div>
      </li>
      <li class="nav-item f-text m-l-10 border-right p-r-10">
        <div class="form-group">
          <label for="direction" class="mb-1 f-w-500">Direction</label>
          <select class="form-control" id="direction" v-model="direction">
            <option value="ASC">ASC</option>
            <option value="DESC">DESC</option>
          </select>
        </div>
      </li>
      <li class="nav-item f-text m-l-10 p-r-10 text-center border-right">
        <div class="form-group">
          <label class="mb-1 f-w-500" for="switch-ticket-show-is-read"
            >Tickets ouverts</label
          >
          <div class="switch switch-primary d-inline m-l-10 d-block">
            <input
              type="checkbox"
              id="switch-ticket-show-is-read"
              v-model="is_read"
            />
            <label for="switch-ticket-show-is-read" class="cr"></label>
          </div>
        </div>
      </li>
      <li class="nav-item f-text m-l-10 p-r-10 text-center border-right">
        <div class="form-group">
          <label class="mb-1 f-w-500" for="switch-ticket-show-all"
            >Ancien Tickets</label
          >
          <div class="switch switch-primary d-inline m-l-10 d-block">
            <input
              type="checkbox"
              id="switch-ticket-show-all"
              v-model="show_all"
            />
            <label for="switch-ticket-show-all" class="cr"></label>
          </div>
        </div>
      </li>
    </ul>
    <div class="nav-item nav-grid f-view btn-group-sm">
      <a
        href="#"
        @click.prevent="closeAllInList"
        class="label text-danger shadow-1 f-w-500 f-12"
        title="Clôturer tous les tickets"
      >
        <span class="feather icon-x-circle" />
        Clôturer la liste
      </a>
    </div>
  </nav>
</template>

<script>
import { ticketFieldMixin } from "../../mixins/ticketFieldMixin";
import { confirmation } from "../../helpers/utils";
import { mapGetters } from "vuex";

export default {
  name: "TicketListHeader",
  mixins: [ticketFieldMixin],
  props: {
    loading: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters("assistance", ["total"]),
    paginates() {
      return [10, 20, 30, 40, 50, 100, 150, 200];
    },
    orderByList() {
      return [
        { code: "tickets.created_at", label: "Date de création" },
        { code: "tickets.updated_at", label: "Dernière mise à jour" },
        { code: "tickets.label", label: "Tickets" },
        { code: "tickets.type_tickets_id", label: "Type de ticket" }
      ];
    }
  },
  methods: {
    closeAllInList() {
      if (this.total > 1) {
        confirmation(
          `Voulez-vous vraiment clôturer tous les ${this.total} tickets de la liste ?`
        ).then(ok => {
          if (ok) {
            this.$store.commit("assistance/UPDATE_FIELD_CLOSE_ALL", true);
            this.$emit("search");
          }
        });
      }
    }
  }
};
</script>

<style scoped></style>
