<template>
  <div class="row align-content-center">
    <div class="col-12" v-if="users_id">
      <div class="alert alert-warning rounded p-2 text-center">
        La liste des tickets ci-dessous est pour un utilisateur spécifique (<b
          >#{{ users_id }}</b
        >)
      </div>
    </div>
    <div class="col-md-3">
      <TicketListFilter @search="searchTickets" />
    </div>
    <div class="col-md-9 filter-bar invoice-list">
      <TicketListHeader @search="getTickets" />
      <div class="card">
        <div class="card-header">
          <h5 class="f-w-500">
            Liste des tickets {{ users_id ? "pour l'utilisateur " : "" }}
            <span class="m-l-10" v-if="total">{{ total }}</span>
          </h5>
          <div class="card-header-right col-lg-4">
            <BaseInputSearch
              @search="getTickets"
              @cancel="cancelSearch"
              v-model="keyword"
            />
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover" v-if="!loading">
            <thead>
              <tr>
                <th style="width: 2px">#</th>
                <th style="width: 20px" class="pl-0">Utilisateur</th>
                <th style="width: 15px">Type</th>
                <th style="width: 60px">Ticket</th>
                <th class="text-center" style="width: 15px">Capture</th>
                <th class="text-right" style="width: 30px">Date de création</th>
                <th style="width: 30px">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="tickets.length > 0">
                <TicketTableLine
                  v-for="(ticket, index) in tickets"
                  :key="index"
                  :ticket="ticket"
                />
              </template>
              <tr v-else class="text-center f-16 mt-2">
                <td colspan="6">
                  <div class="mt-2">
                    Aucun ticket disponible
                    {{
                      users_id
                        ? " pour l'utilisateur spécifié."
                        : " dans la liste"
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <BaseLoader v-if="loading" />
        </div>
        <BaseCardTableFooter
          :current_page="current_page"
          :last_page="last_page"
          @page="changeTicketPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TicketListHeader from "../../components/ticket/TicketListHeader";
import TicketListFilter from "../../components/ticket/TicketListFilter";
import { mapGetters } from "vuex";
import TicketTableLine from "../../components/ticket/TicketTableLine";
import BaseLoader from "../../components/common/BaseLoader";

export default {
  name: "TicketList",
  components: {
    BaseLoader,
    TicketTableLine,
    TicketListFilter,
    TicketListHeader
  },
  data() {
    return {
      loading: true,
      users_id: null
    };
  },
  computed: {
    ...mapGetters("assistance", [
      "current_page",
      "last_page",
      "tickets",
      "total"
    ]),
    keyword: {
      get() {
        return this.$store.state.assistance.ticketField.keyword;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_KEYWORD", value);
      }
    }
  },

  beforeRouteUpdate(routeTo, routeFrom, next) {
    this.$store.commit(
      "assistance/UPDATE_FIELD_TYPE_TICKETS_ID",
      routeTo.params.type_tickets_id || null
    );
    next();
  },

  created() {
    if (this.$route.query.users_id) {
      this.users_id = this.$route.query.users_id;
      this.$store.commit("assistance/UPDATE_FIELD_USERS_ID", this.users_id);
    } else this.$store.commit("assistance/UPDATE_FIELD_USERS_ID", null);

    this.getTickets();
  },

  methods: {
    searchTickets() {
      this.$store.commit("assistance/UPDATE_TICKET_FIELD_PAGE", 1);
      this.getTickets();
    },
    getTickets() {
      this.loading = true;
      this.$store
        .dispatch("assistance/getTicketList")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    cancelSearch() {
      this.$store.commit("assistance/UPDATE_FIELD_KEYWORD", null);
      this.getTickets();
    },

    changeTicketPage(page) {
      this.$store.commit("assistance/UPDATE_TICKET_FIELD_PAGE", page);
      this.getTickets();
    }
  }
};
</script>

<style scoped></style>
