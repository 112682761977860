export const ticketFieldMixin = {
  data() {
    return {
      is_key_change: false
    };
  },
  computed: {
    country_id: {
      get() {
        return this.$store.state.assistance.ticketField.country_id;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_COUNTRY_ID", value);
        this.is_key_change = true;
      }
    },
    type_tickets_id: {
      get() {
        return this.$store.state.assistance.ticketField.type_tickets_id;
      },
      set(value) {
        this.$router.push({
          name: "ticket.list",
          params: { type_tickets_id: value }
        });
        this.is_key_change = true;
      }
    },
    operators_id: {
      get() {
        return this.$store.state.assistance.ticketField.operators_id;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_OPERATORS_ID", value);
        this.is_key_change = true;
      }
    },
    status: {
      get() {
        return this.$store.state.assistance.ticketField.status;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_STATUS", value);
        this.is_key_change = true;
      }
    },
    state: {
      get() {
        return this.$store.state.assistance.ticketField.state;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_STATE", value);
        this.is_key_change = true;
      }
    },
    api_code: {
      get() {
        return this.$store.state.assistance.ticketField.api_code;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_API_CODE", value);
        this.is_key_change = true;
      }
    },
    paginate: {
      get() {
        return this.$store.state.assistance.ticketField.paginate;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_PAGINATE", value);
        this.$emit("search");
      }
    },
    order_by: {
      get() {
        return this.$store.state.assistance.ticketField.order_by;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_ORDER_BY", value);
        this.$emit("search");
      }
    },
    direction: {
      get() {
        return this.$store.state.assistance.ticketField.direction;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_DIRECTION", value);
        this.$emit("search");
      }
    },
    is_read: {
      get() {
        return this.$store.state.assistance.ticketField.is_read;
      },
      set(value) {
        console.log("SET IS READ", value);
        this.$store.commit("assistance/UPDATE_FIELD_IS_READ", value);
        this.$emit("search");
      }
    },
    show_all: {
      get() {
        return this.$store.state.assistance.ticketField.show_all;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_SHOW_ALL", value);
        this.$emit("search");
      }
    },
    is_operation_date: {
      get() {
        return this.$store.state.assistance.ticketField.is_operation_date;
      },
      set(value) {
        this.$store.commit("assistance/UPDATE_FIELD_IS_OPERATION_DATE", value);
        this.is_key_change = true;
      }
    }
  }
};
