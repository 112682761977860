<template>
  <tr class="align-items-center" :title="ticket.description">
    <td style="width: 2px" @click.prevent="openTicketDetails">
      {{ ticket.id }}
    </td>
    <td style="width: 20px" class="pl-0" @click.prevent="openTicketDetails">
      <span class="f-w-500">{{ username | truncate(20) }}</span>
      <small class="d-block text-info">
        {{ ticket.user.email | truncate(20) }}
      </small>
    </td>
    <td style="width: 15px" @click.prevent="openTicketDetails">
      {{ ticket.type.label | truncate(12) }}
      <div class="d-block" v-if="ticket.model">
        <small :class="'text-' + activeClass">
          <i class="fa fa-arrow-alt-circle-up"></i>
          {{ ticket.model.status || ticket.model.phone }}
        </small>
      </div>
    </td>
    <td style="width: 60px" @click.prevent="openTicketDetails">
      {{ ticket.reference }}
      <div class="d-block">
        <span class="badge badge-secondary pt-1 m-r-10" v-if="canShowAmount">
          {{ operationAmount }}</span
        >
        <span class="badge badge-warning pt-1">
          <span class="feather icon-message-square m-r-5" />
          {{ ticket.messages.length }}
        </span>
      </div>
    </td>
    <td class="text-center" style="width: 15px">
      <a
        :href="capture"
        title="Ouvrir la capture"
        v-if="capture"
        target="_blank"
      >
        Consulter
      </a>
      <em class="text-dark f-w-600" v-else>non</em>
    </td>
    <td
      class="text-right"
      style="width: 30px"
      @click.prevent="openTicketDetails"
    >
      {{ ticket.updated_at | moment("DD MMM YYYY") }}
      <small class="d-block">
        <span class="feather icon-clock text-info" />
        {{ ticket.updated_at | moment("h:mm:ss") }}
      </small>
    </td>
    <td style="width: 30px">
      <a
        href="#"
        @click.prevent="closeTicket"
        class="label text-danger shadow-1 f-w-500 f-12"
        title="Clôturer le ticket"
      >
        <span class="feather icon-x-circle" v-if="!loadingClose" />
        <span class="fas fa-spinner fa-pulse" v-else />
        Clôturer
      </a>
      <a
        href="#"
        v-if="isCurrentUserSuperAdmin"
        @click.prevent="disableUserAccount"
        class="mt-2 label text-white bg-danger text-center shadow-1 f-w-500 f-12"
        title="Désactiver l'utilisateur"
      >
        <span class="feather icon-user-x" v-if="!loadingDisable" />
        <span class="fas fa-spinner fa-pulse" v-else />
      </a>
      <a
        href="#"
        v-if="haveOperation"
        @click.prevent="verifyTicketOperation"
        class="mt-2 label text-white bg-success text-center shadow-1 f-w-500 f-12"
        title="Vérifier l'opération"
      >
        <span class="feather icon-refresh-ccw " v-if="!loading" />
        <span class="fas fa-spinner fa-pulse" v-else />
      </a>
      <a
        href="#"
        v-if="haveNumber"
        @click.prevent="verifyTicketNumber"
        class="mt-2 label text-white bg-success text-center shadow-1 f-w-500 f-12"
        title="Valider le numero de telephone"
      >
        <span class="feather icon-check " v-if="!loading" />
        <span class="fas fa-spinner fa-pulse" v-else />
      </a>
    </td>
  </tr>
</template>

<script>
import { ticketMixin } from "../../mixins/ticketMixin";
import { confirmation } from "../../helpers/utils";
import { typeTicketCode } from "../../helpers/constants";

export default {
  name: "TicketTableLine",
  mixins: [ticketMixin],
  data() {
    return {
      loadingClose: false,
      loadingDisable: false
    };
  },
  computed: {
    capture() {
      if (this.ticket.capture) return this.ticket.capture;
      else {
        let result = null;
        this.ticket.ticket_form_values.forEach(tfv => {
          if (tfv.ticket_form.code === "capture") {
            result = tfv.valeur;
          }
        });
        return result;
      }
    },
    haveOperation() {
      return (
        this.ticket.model !== null &&
        this.ticket.type.code !== typeTicketCode.number
      );
    },
    haveNumber() {
      return (
        this.ticket.type.code === typeTicketCode.number && this.model !== null
      );
    }
  },
  methods: {
    openTicketDetails() {
      this.$router.push({
        name: "ticket.detail",
        params: {
          ...this.$route.params,
          reference: this.ticket.reference.toLowerCase()
        }
      });
    },
    closeTicket() {
      confirmation(
        `Voulez-vous vraiment clôturer le ticket ${this.ticket.reference} ?`
      ).then(ok => {
        if (ok) {
          this.loadingClose = true;
          this.$store
            .dispatch("assistance/closeTicket", this.ticket)
            .then(() => {
              this.loadingClose = false;
            })
            .catch(() => {
              this.loadingClose = false;
            });
        }
      });
    },
    disableUserAccount() {
      confirmation(
        "Confirmation de l'action",
        `Voulez-vous vraiment désactiver le compte de l'utilisateur ${this.ticket.user.email} et clôturer tous ses tickets ?`
      ).then(ok => {
        if (ok) {
          this.loadingDisable = true;
          this.$store
            .dispatch("user/disableUserAccount", {
              userId: this.ticket.user.id,
              reason: `Désactiver par l'administrateur lors du contrôle du ticket ${this.ticket.reference}`,
              ticket: this.ticket
            })
            .then(() => {
              this.loadingDisable = false;
            })
            .catch(() => {
              this.loadingDisable = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped></style>
